#projects h2:first-child {
  width: 100%;
}

#projects h2:not(:first-child) {
  flex: 1;
}

#projects {
  justify-content: space-around;
}