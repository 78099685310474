.card {
  border-style: solid;
  border-width: 0px;
  margin: 10px;
  padding: 10px;
  text-align: left;
  display: flex;
  color: white;
  max-width: 30rem;
}

.App-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  padding-bottom: 10px;
}

.card img {
  height: 100px;
  width: 100px;
  margin-right: 10px;
  object-fit:fill;
}

.cards:last-of-type {
  margin-bottom: 10px;
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cards h3 {
  font-size: 1rem;
}

.cards h3, .cards p {
  margin: 0;
}

.cards a:link, .cards a:visited {
  color: #aa49fa;
  text-decoration: none;
}

@media screen and (max-width: 1000px) {
  .App-content {
    grid-template-columns: 1fr;
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    max-width: 60vw;
  }
}

@media screen and (max-width: 651px) {
  .card {
    max-width: unset;
    display: block;
  }

  .card .imgContainer {
    text-align: center;
  }
}