.logos img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(328deg) brightness(102%) contrast(101%);
  width: 5rem;
  height: 3rem;
  margin: 0 10px 20px;
  transition: transform 0.2s;
}

.logos img:hover {
  transform: translateY(-5px);
}

@media screen and (max-width: 651px) {
  .logos img {
    width: 3rem;
    height: 2rem;
  }
}