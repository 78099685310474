.App-header h2 {
  font-size: calc(2vw + 2vh);
  margin-top: 0;
  transition: opacity 0.5s;
}

.App-header {
  background-color: black;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 2rem;
}